// Pagination.js

import React from 'react';
import '../styles/pagination.css'; // Import the CSS file


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
        <div className="pagination">
            <button className="paginate-button"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Anterior
            </button>
            <span>
                Pagina {currentPage} de {totalPages}
            </span>
            <button className="paginate-button"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
            >
                Siguiente
            </button>
        </div>
    );
};

export default Pagination;
