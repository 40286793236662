// src/api.js

import axios from 'axios';

const apiUrl=process.env.REACT_APP_API_URL

// Create an axios instance
const api = axios.create({
  baseURL: apiUrl,
});

// Add a request interceptor to include the JWT token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token && token!=='undefined') {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => response, // Success case
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          // No refresh token, redirect to login
          window.location.href = '/login';
          return Promise.reject(error);
        }

        // Attempt to refresh the token
        //this should not use the api defined above because this will contain a different token
        const { data } = await axios.post( 
          `${apiUrl}token/refresh/`,
          { refresh: refreshToken }
        );

        // Store new tokens
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);

        // Update the Authorization header
        api.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
        originalRequest.headers['Authorization'] = `Bearer ${data.access}`;

        // Retry the original request
        return api(originalRequest);
      } catch (err) {
        // Refresh failed, redirect to login
        console.error('Token refresh failed:', err);
        window.location.href = '/login?logout=true';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export const registerUser = async (formData) => {
  try {
      const response = await api.post(`auth/registration/`, formData);
      return response.data; // Handle the response as needed
  } catch (error) {
      throw error.response.data; // Handle errors as needed
  }
};

export const createProspect = async (formData) => {
  // formData should include: { name, email, phoneNumber, message, listingId }
  console.log(formData)
  try {
      const response = await api.post(`create-prospect/`, formData);
      return response.data; // Handle the response as needed
  } catch (error) {
      throw error.response.data; // Handle errors as needed
  }
};

// Function to fetch a single listing by ID
export const fetchListingById = async (id) => {
    try {
      const response = await api.get(`listings/${id}/`);
      return response.data; // Return the listing data
    } catch (error) {
      throw new Error(`Failed to fetch listing: ${error.message}`);
    }
};

/*
// Function to handle login
export const handleLogin2 = async (username, password) => {
  try {
    const response = await api.post(
      'token/',
      {
        username,
        password,
      }
    );
    // Store tokens in localStorage
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);

    // Set the Authorization header for future requests
    api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

    return response;
  } catch (err) {
    throw err;
  }
};
*/
// Function to handle login
export const handleLogin = async (username, password) => {
  try {
    const response = await api.post(
      'auth/login/',
      {
        username,
        password,
      }
    );
    // Store tokens in localStorage
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
    console.log(response.data)

    // Set the Authorization header for future requests
    api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

    return response;
  } catch (err) {
    throw err;
  }
};


// Function to favorite a listing
export const favoriteListing = async (listingId) => {
  try {
    const response = await api.post(`listings/${listingId}/favorite/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to unfavorite a listing
export const unfavoriteListing = async (listingId) => {
  try {
    const response = await api.delete(`listings/${listingId}/unfavorite/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch map markers
export const fetchMapMarkers = async (
  swLat,
  swLng,
  neLat,
  neLng,
  minPrice,
  maxPrice,
  rooms,
  listingType,
  exactMatch
) => {
  try {
    const response = await api.get('/listings/in_bounds_markers/', {
      params: {
        sw_lat: swLat,
        sw_lng: swLng,
        ne_lat: neLat,
        ne_lng: neLng,
        listingType,
        ...(minPrice !== null && { min_price: minPrice }),
        ...(maxPrice !== null && { max_price: maxPrice }),
        ...(rooms !== null && { rooms: rooms }),
        ...(exactMatch !== undefined && { exactMatch: exactMatch })
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch map markers: ${error.message}`);
  }
};

// Fetch paginated listings
export const fetchPaginatedListings = async (
  swLat,
  swLng,
  neLat,
  neLng,
  minPrice,
  maxPrice,
  rooms,
  listingType,
  exactMatch,
  page = 1,
  perPage = 20
) => {
  try {
    const response = await api.get('/listings/in_bounds_paginated/', {
      params: {
        sw_lat: swLat,
        sw_lng: swLng,
        ne_lat: neLat,
        ne_lng: neLng,
        listingType,
        page: page,
        per_page: perPage,
        ...(minPrice !== null && { min_price: minPrice }),
        ...(maxPrice !== null && { max_price: maxPrice }),
        ...(rooms !== null && { rooms: rooms }),
        ...(exactMatch !== undefined && { exactMatch: exactMatch }),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch listings: ${error.message}`);
  }
};

export const handleGoogleLogin = async (idToken) => {
    try {
      const response = await api.post(
        'social-login/google/',
        {
            id_token: idToken,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Store tokens in localStorage
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
  
      // Set the Authorization header for future requests
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
  
      return response.data;
    } catch (err) {
      throw err.response?.data || err;
    }
  };

// Function to create a new listing
export const createListing = async (listingData) => {
  try {
    const formData = new FormData();

    // Append each field to the formData
    for (const key in listingData) {
      if (key === 'uploaded_images') {
        // 'uploaded_images' is the field name expected by your serializer
        listingData.uploaded_images.forEach((image) => {
          formData.append('uploaded_images', image);
        });
      } else if (key === 'uploaded_amenities') {
        // If you have amenities, append them as individual items
        listingData.uploaded_amenities.forEach((amenity) => {
          formData.append('uploaded_amenities', amenity);
        });
      } else {
        // Append other fields
        formData.append(key, listingData[key]);
      }
    }

    // Send the POST request using the axios instance
    const response = await api.post('/listings/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // The 'Authorization' header is automatically set by the interceptor
      },
    });

    return response.data; // Return the created listing data
  } catch (error) {
    // Handle errors
    if (error.response) {
      // Server responded with a status other than 2xx
      throw error.response.data;
    } else {
      // Other errors (e.g., network issues)
      throw error;
    }
  }
};

export const fetchUserFavorites = async (page = 1, pageSize=20) => {
  try {
    const response = await api.get('/favorites/', {
      params: {
        page: page,
        page_size : pageSize
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserListings = async (page = 1, pageSize=20) => {
  try {
    const response = await api.get('/my-listings/', {
      params: {
        page: page,
        page_size:pageSize,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmEmail = async (key) => {
  try {
    const response = await api.post('auth/registration/verify-email/', { key });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data; // Re-throw the error for the calling component to handle
    } else {
      throw new Error('An unexpected error occurred during email confirmation.');
    }
  }
};