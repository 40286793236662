// src/components/ContactAgentButton.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { createProspect } from '../api'; // Adjust this import as needed

const ContactAgentButton = ({ listingId }) => {
  const [showForm, setShowForm] = useState(false);
  const [showSuccessOverlay, setShowSuccessOverlay] = useState(false); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const openForm = () => {
    setShowForm(true);
    setError(null);
    // Blur the page content when modal is open
    const pageContent = document.querySelector('.page-content');
    if (pageContent) {
      pageContent.classList.add('blurred');
    }
  };

  const closeForm = () => {
    setShowForm(false);
    setName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setError(null);
    // We do not remove blur here yet if we're going to show a success overlay later.
    // We'll remove it once the success overlay disappears or if user closes the form normally.
    const pageContent = document.querySelector('.page-content');
    if (pageContent && !showSuccessOverlay) {
      pageContent.classList.remove('blurred');
    }
  };

  const closeAll = () => {
    // When success overlay disappears, remove blur and reset all states
    setShowSuccessOverlay(false);
    const pageContent = document.querySelector('.page-content');
    if (pageContent) {
      pageContent.classList.remove('blurred');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const data = {
        name,
        email,
        phoneNumber,
        message,
        listingId
      };
      await createProspect(data);
      
      // On success: close the form
      closeForm();
      // Show a separate success overlay
      setShowSuccessOverlay(true);
      // Hide success overlay after 2 seconds
      setTimeout(() => {
        closeAll();
      }, 3000);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const modalContent = showForm ? (
    <div className="modal-overlay">
      <div className="modal-container">
        {/* Close (X) button at the top right corner */}
        <button className="close-button" onClick={closeForm} aria-label="Cerrar formulario">
          &times;
        </button>
        <h2>Contactar a un Agente</h2>
        <form onSubmit={handleSubmit}>
          
          <div className="form-group">
            <label>Nombre:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Teléfono:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Mensaje:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Estoy interesado en la publicación..'
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          {/* Center the Enviar button */}
          <div className="form-buttons">
            <button type="submit" disabled={loading}>
              {loading ? 'Enviando...' : 'Enviar'}
            </button>
          </div>

          <div className="form-info">
            Al enviar tu información, consientes a que uno de nuestros agentes se ponga en contacto contigo.
            <br />
            Como comprador, la comisión del agente ya está contemplada en el precio de venta de la propiedad. No contar con un agente es como pagar por un servicio que no estás utilizando.
            <br />
          </div>
        </form>
      </div>
    </div>
  ) : null;

  const successOverlayContent = showSuccessOverlay ? (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>¡Listo!</h2>
        <p>Hemos recibido tu información y uno de nuestros agentes se pondrá en contacto contigo a la brevedad.</p>
      </div>
    </div>
  ) : null;

  return (
    <div>
      <button onClick={openForm} className="contact-agent-button">
        Contactar Agente
      </button>
      {ReactDOM.createPortal(modalContent, document.getElementById('modal-root'))}
      {ReactDOM.createPortal(successOverlayContent, document.getElementById('modal-root'))}
    </div>
  );
};

export default ContactAgentButton;
