import React, { useState } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import SearchBar from '../SearchBarCreateListing';

const Step1BasicInfo = ({ listingData, onChange, nextStep, getGeoData }) => {
  const [errors, setErrors] = useState({});

  const validateAndProceed = () => {
    const newErrors = {};
    if (!listingData.address) newErrors.address = 'Address is required';
    if (!listingData.property_type) newErrors.property_type = 'Property type is required';
    if (!listingData.listing_type) newErrors.listing_type = 'Listing type is required';

    if (Object.keys(newErrors).length === 0) {
      nextStep();
    } else {
      setErrors(newErrors);
    }
  };


  const handleAddressSelect = (data) => {
    const { place_name, center, context } = data; // Destructure place_name from data
    getGeoData(data); // Pass the full data object
    onChange({ address: place_name, 
        longitude: center[0], 
        latitude:center[1],
        state:context[2].text,
        city: context[1].text,
        zip_code: context[0].text}); // Pass both the full object and place_name separately
};

  const handlePropertyTypeChange = (e) => {
    onChange({ property_type: e.target.value });
  };

  const handleListingTypeChange = (e) => {
    const listing_type = e.target.value;
    onChange({
        listing_type,
      isSharedSpace: listing_type === 'Rent' ? listingData.isSharedSpace : false,
    });
  };

  const handleSharedSpaceChange = (e) => {
    onChange({ isSharedSpace: e.target.checked });
  };

  const handleUnitNumberChange = (e) => {
    onChange({ unitNumber: e.target.value });
  };

  return (
    <div className="step-container">
      <div className="step-box">
        <h2 className="step-title">Paso 1: Información Básica</h2>
        <div className="form-group">
            <label>Dirección (Requerido)</label>
          <SearchBar onLocationSelect={handleAddressSelect} />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>
        <div className="form-group">
          <label>
            Numero Interior:
            <input
              type="text"
              value={listingData.unitNumber || ''}
              onChange={handleUnitNumberChange}
              className="form-input"
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Tipo de Propiedad (Requerido):
            <select
              value={listingData.property_type}
              onChange={handlePropertyTypeChange}
              className="form-select"
            >
              <option value="">Selecciona el tipo de propiedad</option>
              <option value="house">Casa</option>
              <option value="condo">Departamento</option>
              <option value="office">Oficina</option>
              <option value="villa">Quinta</option>
            </select>
            {errors.property_type && <span className="error">{errors.property_type}</span>}
          </label>
        </div>
        <div className="form-group">
          <label>
            Tipo de Listado (Requerido):
            <select
              value={listingData.listing_type}
              onChange={handleListingTypeChange}
              className="form-select"
            >
              <option value="">Selecciona el tipo de listado</option>
              <option value="rent">Renta</option>
              <option value="sell">Venta</option>
            </select>
            {errors.listing_type && <span className="error">{errors.listing_type}</span>}
          </label>
        </div>
        {listingData.listing_type === 'rent' && (
            <div className='form-group'>
                <div className="form-checkbox">
                    <label className='form-checkbox-label' htmlFor='rent'>
                    Se renta solo una parte de la propiedad?
                    </label>
                    <input
                        id='rent'
                        type="checkbox"
                        checked={listingData.isSharedSpace}
                        onChange={handleSharedSpaceChange}
                        className='form-checkbox'
                    />
                </div>
            </div>
        )}
        <div className='step-contols'>
        <button className="next-step-button" onClick={validateAndProceed}>
          Siguiente
        </button>
        </div>
      </div>
    </div>
  );
};

export default Step1BasicInfo;
