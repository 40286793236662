// src/components/StreetViewModal.js
import ReactDOM from 'react-dom';
import React from 'react';

const StreetViewModal = ({ onClose, children }) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-container-map">
        <button className="close-button" onClick={onClose}>&times;</button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default StreetViewModal;
