import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListingItem from '../components/ListingItem';
import Pagination from '../components/Pagination';
import { fetchUserListings } from '../api';

const MyListings = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Total pages from backend
  const navigate = useNavigate();

  const pageSize=20;

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (!token || token === 'undefined') {
      navigate('/login', { state: { fromMyListings: true } });
    } else {
      // Fetch the user's listings
      fetchUserListings(currentPage)
        .then((data) => {
          setListings(data.results);
          setTotalPages(Math.ceil(data.count / pageSize)); // Assuming page_size is provided
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user listings:', error);
          setLoading(false);
          setError('Failed to load your listings. Please try again later.');
          if (error.response && error.response.status === 401) {
            navigate('/login', { state: { fromMyListings: true } });
          }
        });
    }
  }, [navigate, currentPage]);

  const handleFavoriteChange = (listingId) => {
    // Update the favorite status in the listings state
    setListings((prevListings) =>
      prevListings.map((listing) =>
        listing.id === listingId
          ? { ...listing, is_favorited: !listing.is_favorited }
          : listing
      )
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className="my-listings-container">
        <h2>My Listings</h2>
        <p>Loading your listings...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="my-listings-container">
        <h2>My Listings</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="my-listings-container">
      <h2>My Listings</h2>
      {listings.length > 0 ? (
        <>
          <div className="my-listings-grid">
            {listings.map((listing) => (
              <ListingItem
                key={listing.id}
                listing={listing}
                onFavoriteChange={() => handleFavoriteChange(listing.id)}
                onHover={() => {}}
                onLeave={() => {}}
              />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <p>You have not created any listings yet.</p>
      )}
    </div>
  );
};

export default MyListings;
