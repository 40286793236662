// EmailConfirmation.js

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmEmail } from '../api';

function EmailConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const key = queryParams.get('key');

    if (key) {
      // Use the confirmEmail function from api.js
      confirmEmail(key)
        .then(() => {
          // Show success message and redirect to login page
          alert('Your email has been confirmed successfully!');
          navigate('/login');
        })
        .catch((error) => {
          // Handle errors (e.g., invalid key)
          alert('Email confirmation failed. Please try again.');
          navigate('/register'); // Redirect to registration page or appropriate action
        });
    } else {
      // Handle missing key
      alert('Invalid confirmation link.');
      navigate('/register');
    }
  }, [location, navigate]);

  return (
    <div>
      <h1>Email Confirmation</h1>
      <p>Confirming your email, please wait...</p>
    </div>
  );
}

export default EmailConfirmation;
