import React, { useState } from 'react';
import { createListing } from '../../api'; // Adjust the import path as needed
import CurrencyInput from 'react-currency-input-field';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const Step3AdditionalDetails = ({ listingData, onChange, prevStep }) => {
  const [errors, setErrors] = useState({});

  const validateAndSubmit = () => {
    const newErrors = {};
    if (!listingData.price) newErrors.price = 'Price is required';
    if (!listingData.description) newErrors.description = 'A description is required';
    if (!listingData.contact_phone) newErrors.contact_phone = 'Contact phone is required';
    if (!listingData.number_of_rooms) newErrors.number_of_rooms = 'Number of bedrooms is required';
    if (!listingData.number_of_bathrooms) newErrors.number_of_bathrooms = 'Number of bathrooms is required';
    if (!listingData.construction_area) newErrors.construction_area = 'Construction area is required';
    if (listingData.uploaded_images.length === 0) newErrors.uploaded_images = 'At least one photo is required';

    if (Object.keys(newErrors).length === 0) {
      // Submit the listing
      // For example, make an API call to save the listing
      console.log('Listing submitted:', listingData);
      handleSubmit(listingData)
    } else {
      setErrors(newErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    onChange({ [name]: type === 'checkbox' ? checked : value });
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    // 'value' is the raw phone number without '+' sign
    // Prepend '+' to match E.164 format
    const phoneNumber = '+' + value;
    onChange({ contact_phone: phoneNumber });
  };

  const handlePriceChange = (value) => {
    onChange({ price: value });
  };
 
    const handleSubmit = async () => {
    // Construct the listingData object with all the required fields

    try {
        const newListing = await createListing(listingData);
        // Redirect the user or display a success message
    } catch (error) {
        console.error('Error creating listing:', error);
        // Display an error message to the user
    }
    };


  return (
    <div className="step-container">
      <div className="step-box">
      <div className='step-title'>Step 3: Additional Details</div>
      
      <div className='form-group'>
        <label>
          Price MXN (required):
          <CurrencyInput
            className='form-input'
            name="price"
            placeholder="Please enter a price"
            value={listingData.price}
            decimalsLimit={0}
            prefix="$"
            onValueChange={handlePriceChange}
          />
          {errors.price && <span className="error">{errors.price}</span>}
        </label>
      </div>
      <div className='form-group'>
        <label>
          Description (required):
          <input
            className='form-input'
            type="text"
            name="description"
            value={listingData.description}
            onChange={handleInputChange}
          />
          {errors.description && <span className="error">{errors.description}</span>}
        </label>
      </div>
      <div className='form-group'>
        <label>
          Contact Phone (required):
          <PhoneInput
            country={'mx'} // Set default country to Mexico
            value={listingData.contact_phone}
            onChange={handlePhoneChange}
            inputClass="form-input"
            countryCodeEditable={true} // Allow users to change the country code
            enableSearch={true} // Allow users to search for countries
          />
          {errors.contact_phone && <span className="error">{errors.contact_phone}</span>}
        </label>
      </div>
      <div className='form-group-2col'>
        <label>
          Number of Bedrooms (required):
          <input
            className='form-input'
            type="number"
            name="number_of_rooms"
            value={listingData.number_of_rooms}
            onChange={handleInputChange}
          />
          {errors.number_of_rooms && <span className="error">{errors.number_of_rooms}</span>}
        </label>
        <label>
          Construction Area (required):
          <input
            className='form-input'
            type="number"
            name="construction_area"
            value={listingData.construction_area}
            onChange={handleInputChange}
          />
          {errors.construction_area && <span className="error">{errors.construction_area}</span>}
        </label>
      
      </div>
      <div className='form-group-2col'>
        <label>
          Number of Bathrooms (required):
          <input
            className='form-input'
            type="number"
            name="number_of_bathrooms"
            value={listingData.number_of_bathrooms}
            onChange={handleInputChange}
          />
          {errors.number_of_bathrooms && <span className="error">{errors.number_of_bathrooms}</span>}
        </label>
        <label>
          Number of Half Bathrooms (optional):
          <input
            className='form-input'
            type="number"
            name="number_of_half_bathrooms"
            value={listingData.number_of_half_bathrooms}
            onChange={handleInputChange}
          />
        </label>
      </div>
     
      <div className='form-group'>
        <label>
          Photos (at least 1 required):
          <input
            className='form-input'
            type="file"
            name="uploaded_images"
            multiple
            accept="image/*"
            onChange={(e) => onChange({ uploaded_images: Array.from(e.target.files) })}
          />
          {errors.uploaded_images && <span className="error">{errors.uploaded_images}</span>}
        </label>
      </div>
      {/* Add other optional fields here */}
      <div className='step-controls'>
        <div className='step-back' onClick={prevStep}>Back</div>
        {/* Add logic to try again in 1 sec if it fails */}
        <button className="next-step-button" onClick={validateAndSubmit}>Publish</button> 
      </div>
    </div>
    </div>
  );
};

export default Step3AdditionalDetails;
