import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Listings from './pages/Listings';
import Homepage from './pages/Homepage';
import Register from './pages/Register';
import Login from './pages/Login';
import ListingDetail from './pages/ListingDetail';
import CreateListingForm from './components/CreateListing/CreateListingForm';
import '@fortawesome/fontawesome-free/css/all.css';
import Favorites from './pages/Favorites';
import MyListings from './pages/MyListings';
import EmailConfirmation from './pages/EmailConfirmation';


// ... inside your Routes component:


const App = () => {
    return (
        <Router>
            <Navbar />
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/listings" element={<Listings />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/listing/:id" element={<ListingDetail />} />
                    <Route path="/create-listing" element={<CreateListingForm />} />
                    <Route path="/favorites" element={<Favorites />} />
                    <Route path="/my-listings" element={<MyListings />} />
                    <Route path="/confirm-email" element={<EmailConfirmation />} />
                </Routes>
            </div>
            <div id="modal-root"></div>
        </Router>
    );
};

export default App;
