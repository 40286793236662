// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pagination.css */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 10px;
    box-sizing: border-box;
    height: 7%;
    /* Ensure pagination does not get squeezed */
}

@media (max-width: 500px) {
    .pagination {
        margin-bottom: 5vh;
    }
}

button.paginate-button {
    background-color: rgb(0 31 63);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    border: #333333;
    padding: 5px;
    margin: 0px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Hover state */
button.paginate-button:hover {
    background-color: rgb(0 31 63);
    /* Change to a lighter or darker color on hover */
}

button.paginate-button:disabled:hover {
    background-color: rgb(223, 223, 223);
    cursor: auto;
}

button.paginate-button:disabled {
    background-color: rgb(211, 211, 211);

}`, "",{"version":3,"sources":["webpack://./src/styles/pagination.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,4CAA4C;AAChD;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA,gBAAgB;AAChB;IACI,8BAA8B;IAC9B,iDAAiD;AACrD;;AAEA;IACI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,oCAAoC;;AAExC","sourcesContent":["/* Pagination.css */\n\n.pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-shrink: 0;\n    padding: 10px;\n    box-sizing: border-box;\n    height: 7%;\n    /* Ensure pagination does not get squeezed */\n}\n\n@media (max-width: 500px) {\n    .pagination {\n        margin-bottom: 5vh;\n    }\n}\n\nbutton.paginate-button {\n    background-color: rgb(0 31 63);\n    color: white;\n    cursor: pointer;\n    border-radius: 5px;\n    border: #333333;\n    padding: 5px;\n    margin: 0px 10px;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n/* Hover state */\nbutton.paginate-button:hover {\n    background-color: rgb(0 31 63);\n    /* Change to a lighter or darker color on hover */\n}\n\nbutton.paginate-button:disabled:hover {\n    background-color: rgb(223, 223, 223);\n    cursor: auto;\n}\n\nbutton.paginate-button:disabled {\n    background-color: rgb(211, 211, 211);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
