import React, { useState, useEffect } from 'react';
import GoogleLoginButton from '../components/GoogleLoginButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { handleLogin } from '../api';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const location = useLocation(); // Access the current location
  const navigate = useNavigate(); // For navigation after login

  // Access the state passed from navigation
  const fromCreateListing = location.state?.fromCreateListing || false;
  const fromFavorites = location.state?.fromFavorites || false;
  const fromMyListings = location.state?.fromMyListings || false;
  const fromRegistration = location.state?.registered || false;



  // Check if tokens are present on initial load
  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    // Check for 'logout' parameter in URL
    const queryParams = new URLSearchParams(location.search);
    const logoutParam = queryParams.get('logout'); // Looking for ?logout=true

    if (logoutParam === 'true') {
      // If the 'logout' param is set, we want to log out the user
      setIsLoggedIn(false);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    } else if (accessToken) {
      setIsLoggedIn(true);
    }
  }, [location.search]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      await handleLogin(username, password);
      setIsLoggedIn(true);
      // Redirect to previous page if available
      if (fromCreateListing) {
        navigate('/create-listing');
      } else {
        navigate('/');
      }
    } catch (err) {
      console.error('Login Error:', err);
      setError('Invalid username or password');
    }
  };

  // Handler for successful Google login
  const handleGoogleLoginSuccess = (data) => {
    console.log('Google login success data:', data);
    setIsLoggedIn(true);
    // Redirect to previous page if available
    if (fromCreateListing) {
      navigate('/create-listing');
    } else {
      navigate('/');
    }
  };

  // Handler for failed Google login
  const handleGoogleLoginFailure = (errorData) => {
    console.error('Google Login Error:', errorData);
    setError('Google login failed. Please try again.');
  };

  // Logout handler
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    navigate('/login?logout=true'); // Redirect to login page with logout param
  };

  return (
    <div className="login-container">
      {(fromCreateListing || fromFavorites || fromMyListings) && (
              <div className="alert alert-warning">
                You must be logged in to create a listing.
              </div>
            )}
      {/*      
      {(fromRegistration) && (
              <div className="alert alert-warning">
                Debes verificar tu correo antes de continuar.
              </div>
            )}
      */}
      <div className="login-box">
        {isLoggedIn ? (
          <div className="logged-in-container">
            <h2 className="login-title">You are logged in</h2>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        ) : (
          <>
            <h2 className="login-title">Login</h2>
            {/* Display the alert if the user was redirected from Create Listing */}
            
            {/* Traditional Login Form */}
            <form className="login-form" onSubmit={handleLoginSubmit}>
              <input
                type="text"
                className="login-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username or Email"
                required
              />
              <input
                type="password"
                className="login-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <button type="submit" className="login-submit-button">
                Login
              </button>
            </form>
            {error && <p className="login-error">{error}</p>}

            {/* Register Option */}
            <p className="login-register">
              Aún no tienes cuenta?{' '}
              <Link to="/register" className="register-link">
                Regístrate Aquí
              </Link>
            </p>

            <hr className="login-divider" />

            {/* Google Login Button */}
            <GoogleOAuthProvider clientId={clientId}>
              <div className="google-login-container">
                <GoogleLoginButton
                  onLoginSuccess={handleGoogleLoginSuccess}
                  onLoginFailure={handleGoogleLoginFailure}
                />
              </div>
            </GoogleOAuthProvider>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
