import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListingItem from '../components/ListingItem';
import Pagination from '../components/Pagination';
import { fetchUserFavorites } from '../api';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Total pages from backend
  const navigate = useNavigate();

  const pageSize=20;

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (!token || token === 'undefined') {
      navigate('/login', { state: { fromFavorites: true } });
    } else {
      // Fetch the user's favorites
      fetchUserFavorites(currentPage, pageSize)
        .then((data) => {
            console.log(data)
          setFavorites(data.results);
          setTotalPages(Math.ceil(data.count / pageSize)); // Assuming page_size is provided
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching favorites:', error);
          setLoading(false);
          setError('Failed to load your favorites. Please try again later.');
          if (error.response && error.response.status === 401) {
            navigate('/login', { state: { fromFavorites: true } });
          }
        });
    }
  }, [navigate, currentPage]);

  const handleFavoriteChange = (listingId) => {
    // Remove the listing from the favorites state
    setFavorites((prevFavorites) =>
      prevFavorites.filter((listing) => listing.id !== listingId)
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className="favorites-container">
        <h2>My Favorites</h2>
        <p>Loading your favorites...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="favorites-container">
        <h2>My Favorites</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="favorites-container">
      <h2>My Favorites</h2>
      {favorites.length > 0 ? (
        <>
          <div className="favorites-grid">
            {favorites.map((listing) => (
              <ListingItem
                key={listing.id}
                listing={listing}
                onFavoriteChange={() => handleFavoriteChange(listing.id)}
                onHover={() => {}}
                onLeave={() => {}}
              />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <p>You have no favorites yet.</p>
      )}
    </div>
  );
};

export default Favorites;
