import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import '../styles/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { favoriteListing, unfavoriteListing } from '../api';
import React, { useState, useEffect, useRef, memo } from 'react';


const ListingItem = memo(({ listing, onFavoriteChange, onHover, onLeave }) => {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(listing.is_favorited);
  // State and ref for lazy loading
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef(null);

  // Intersection Observer to detect when the component is in view
  useEffect(() => {
    let observer;
    if (imgRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            if (imgRef.current) {
              observer.unobserve(imgRef.current);
            }
          }
        },
        {
          rootMargin: '100px', // Load images slightly before they come into view
          threshold: 0.01,
        }
      );
      observer.observe(imgRef.current);
    }
    return () => {
      if (observer && imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);
  

  const handleFavorite = async () => {
    const accessToken = localStorage.getItem('access_token');

    // If no access token, prompt the user to log in
    if (!accessToken) {
      alert('Please log in to like the listing.');
      return;
    }

    setIsFavorite(!isFavorite); // Optimistically update the UI

    try {
      if (!isFavorite) {
        await favoriteListing(listing.id);
      } else {
        await unfavoriteListing(listing.id);
      }
      onFavoriteChange();
    } catch (error) {
      setIsFavorite(isFavorite); // Revert UI on failure
      console.error('Error updating favorite status:', error);

      // Handle 401 Unauthorized errors
      if (error.response && error.response.status === 401) {
        alert('Your session has expired. Please log in again.');
        window.location.href = '/login';
      }
    }
  };

  function formatPrice(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(value);
  }

  const handleImageClick = (e) => {
    if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'IMG') {
      navigate(`/listing/${listing.id}`);
    }
  };

  return (
    <div
      className="listing-item"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      ref={imgRef} // Attach ref here to observe the entire item
    >
      <div className="image-container" onClick={handleImageClick}>
        {listing.images.length > 0 ? (
            <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                showArrows={true}
                autoPlay={true}
                infiniteLoop
                interval={4000}
                lazyLoad={true} // Enable lazy loading in Carousel
                className="carousel" // Assign a class for identification
                onClickItem={(index, item) => item.tagName === 'IMG' && navigate(`/listing/${listing.id}`)} // Only trigger on image click
            >
                {listing.images.map((image, index) => (
              
                <img key={index} src={image.image} alt={listing.title} loading="lazy" />
                ))}
            </Carousel>
        ) : (
          <img 
            src={listing.images[0].image} 
            alt={listing.title} 
            loading="lazy"
            onClick={() => navigate(`/listing/${listing.id}`)}
          />
        )}
      </div>
      <div className="price">{formatPrice(listing.price)}</div>
      <div className="listing-attributes-inline-flex">
        <div className="attribute">
          <span className="value">{Math.round(listing.lot_area)}</span>
          <span className="label">m<sup>2</sup></span>
        </div>
        <div className="separator">|</div>
        <div className="attribute">
          <span className="value">{listing.number_of_rooms}</span>
          <span className="label">Hab</span>
        </div>
        <div className="separator">|</div>
        <div className="attribute">
          <span className="value">{listing.number_of_bathrooms}</span>
          <span className="label">Ba&ntilde;</span>
        </div>
      </div>
      {listing.is_promoted && (
        <div className="promoted-banner">
            Promoted
        </div>
      )}
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevent the click from triggering the listing click handler
          handleFavorite();
        }}
        className={`favorite-button ${isFavorite ? 'liked' : ''}`}
      >
        <i className={isFavorite ? 'fas fa-heart' : 'far fa-heart'}></i>
      </button>
    </div>
  );
});

export default ListingItem;
