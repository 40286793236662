// Navbar.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.css';
import logo from '../images/logo-no-background.png';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup function
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Left-aligned links */}
        <div className="navbar-left">
          <ul className="navbar-menu">
            <li>
              <Link to="/">Inicio</Link>
            </li>
          </ul>
        </div>

        {/* Centered logo */}
        <div className="navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        {/* Right-aligned menu */}
        <div className="navbar-right">
          <ul className="navbar-menu">
            {/* Dropdown Menu */}
            <li className="dropdown" ref={dropdownRef}>
              <button className="dropbtn" onClick={toggleDropdown}>
                Menu
                <i className="fa fa-caret-down"></i>
              </button>
              <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                <Link to="/favorites">Favoritos</Link>
                <Link to="/my-listings">Mis Listados</Link>
                <Link to="/create-listing">Crear Listado</Link>
              </div>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
