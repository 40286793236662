import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import { handleGoogleLogin } from '../api';

const GoogleLoginButton = ({ onLoginSuccess, onLoginFailure }) => {
  return (
    <GoogleLogin
      onSuccess={async (credentialResponse) => {
        const idToken = credentialResponse.credential;

        if (!idToken) {
          console.error('No id_token received');
          if (onLoginFailure) {
            onLoginFailure('No id_token received');
          }
          return;
        }

        try {
          // Call the function from api.js
          const resData = await handleGoogleLogin(idToken);
          if (onLoginSuccess) {
            onLoginSuccess(resData);
          }
        } catch (err) {
          console.error('Login failed:', err);
          if (onLoginFailure) {
            onLoginFailure(err);
          }
        }
      }}
      onError={(errorResponse) => {
        console.error('Google login failed:', errorResponse);
        if (onLoginFailure) {
          onLoginFailure(errorResponse);
        }
      }}
      useOneTap={false} // Set to true if you want to enable One Tap login
    />
  );
};

GoogleLoginButton.propTypes = {
  onLoginSuccess: PropTypes.func,
  onLoginFailure: PropTypes.func,
};

export default GoogleLoginButton;
