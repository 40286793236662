//ListingsMap.js
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import '../styles/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import ListingItem from './ListingItem';
import { fetchListingById } from '../api';

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = mapboxAccessToken;

const ListingsMap = ({ markers, onBoundsChange, mapRef , onFavoriteChange, mapBounds}) => {
    const mapContainerRef = useRef(null);
    const markersRef = useRef({});
    const [selectedListing, setSelectedListing] = useState(null);
    const [listingPosition, setListingPosition] = useState({ top: 0, left: 0 });
    const listingRef = useRef(null);
    const [loadingListing, setLoadingListing] = useState(false);

    const formatPrice = (price) => {
        if (price >= 1000000000) {
            return `${(price / 1000000000).toFixed(1)}KM`; // For billions
        } else if (price >= 1000000) {
            return `${(price / 1000000).toFixed(1)}M`; // For millions
        } else if (price >= 1000) {
            return `${(price / 1000).toFixed(1)}K`; // For thousands
        } else {
            return price.toString(); // For smaller amounts
        }
    };
    // Function to create custom markers
    const createMarkerElement = (listingId, listingPrice) => {
          // Create the main marker container
        const markerDiv = document.createElement('div');

        markerDiv.className = 'custom-marker';

        // Add price as text or any other content to the child element
        markerDiv.innerText = `$${formatPrice(listingPrice)}`;

        // Append the content to the marker container
        markerDiv.id = `marker-${listingId}`;
        return markerDiv;
    };

    // Function to add markers to the map
    const addMarkers = (markers) => {
        if (!mapRef.current) return;

        // Clear existing markers
        Object.values(markersRef.current).forEach(marker => marker.remove());
        markersRef.current = {};

        markers.forEach((markerData) => {
            const { latitude, longitude, id , price} = markerData;
            if (latitude && longitude) {
                const markerElement = createMarkerElement(id, price);
                const marker = new mapboxgl.Marker(markerElement)
                    .setLngLat([longitude, latitude])
                    .addTo(mapRef.current);
                
                // Add click event to marker
                marker.getElement().addEventListener('click', () => {
                    // Fetch the full listing data
                    setLoadingListing(true);
                    fetchListingById(id)
                        .then(listingData => {
                            setSelectedListing(listingData);

                            // Calculate the pixel position of the marker
                            const markerPos = mapRef.current.project([longitude, latitude]);

                            // Get the map size in CSS pixels
                            const mapContainer = mapRef.current.getContainer();
                            const mapWidth = mapContainer.clientWidth;
                            const mapHeight = mapContainer.clientHeight;

                            // Convert marker positions to percentages
                            const markerPosPercentage = {
                                x: (markerPos.x / mapWidth) * 100,
                                y: (markerPos.y / mapHeight) * 100,
                            };
                            console.log(markerPos, mapWidth, mapHeight)
                            // Define thresholds as percentages
                            const topThreshold = 40;    // 10% from the top
                            const bottomThreshold = 0; // 10% from the bottom
                            const leftThreshold = 20;    // 5% from the left
                            const rightThreshold = 20;   // 5% from the right
                
                            // Adjust position based on proximity to map edges
                            let topPercentage = markerPosPercentage.y-3;
                            let leftPercentage = markerPosPercentage.x;
                
                            // Vertical adjustments
                            if (markerPosPercentage.y < topThreshold) {
                                topPercentage += 37;
                            } else if (markerPosPercentage.y > 100 - bottomThreshold) {
                                topPercentage -= 0;
                            }
                
                            // Horizontal adjustments
                            if (markerPosPercentage.x > 100 - rightThreshold) {
                                leftPercentage -= 30-(100-markerPosPercentage.x);
                            } else if (markerPosPercentage.x < leftThreshold) {
                                leftPercentage += -80+(100-markerPosPercentage.x);
                            }
                
                            // Set the position as percentages
                            setListingPosition({ top: topPercentage, left: leftPercentage });
                        })
                        .catch(error => {
                            console.error('Failed to fetch listing data:', error);
                        })
                        .finally(() => {
                            setLoadingListing(false);
                        });
                });
                markersRef.current[id] = marker;
            }
        });
    };
    const highlightMarker = (listingId) => {
        const marker = markersRef.current[listingId];
        if (marker) {
            const markerElement = marker.getElement();
            // add 'highlight' class from custom marker
            markerElement.classList.add('highlight');
            }
        };

    const resetMarker = (listingId) => {
        const marker = markersRef.current[listingId];
        if (marker) {
            const markerElement = marker.getElement();
            // Remove 'highlight' class from custom marker
            markerElement.classList.remove('highlight');
            }
        };
    
    // Event listener for updating bounds
    useEffect(() => {
        if (mapRef.current) { //a map should already exist
            const mapInstance = mapRef.current;
           
            // Debounce function
            const debounce = (func, delay) => {
                let timeoutId;
                return (...args) => {
                // Clear the previous timer
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                // Set a new timer
                timeoutId = setTimeout(() => {
                    func.apply(null, args);
                }, delay);
                };
            };

            const updateBounds = debounce(() => {
                if (mapInstance.isStyleLoaded()) {
                    const bounds = mapInstance.getBounds();
                    console.log('Map Bounds calculated from map:', bounds);
                    if (onBoundsChange) {
                        onBoundsChange(bounds);
                    }
                }
            }, 200); // Adjust the delay as needed
  
            // Attach event listeners
            mapInstance.on('moveend', updateBounds);
            mapInstance.on('zoomend', updateBounds);
            mapInstance.on('dragend', updateBounds);
            mapInstance.on('pitchend', updateBounds);
            mapInstance.on('rotateend', updateBounds);
            mapInstance.on('idle', updateBounds);//this has the side effect of also loading map bounds at startup

            // Clean up event listeners on unmount or when mapRef.current changes
            return () => {
                mapInstance.off('moveend', updateBounds);
                mapInstance.off('zoomend', updateBounds);
                mapInstance.off('dragend', updateBounds);
                mapInstance.off('pitchend', updateBounds);
                mapInstance.off('rotateend', updateBounds);
                mapInstance.off('idle', updateBounds);

            };
        }
    }, [mapRef,onBoundsChange]);

    // Close the listing when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (listingRef.current && !listingRef.current.contains(event.target)) {
                setSelectedListing(null); // Close the popup
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [listingRef]); //good

    // Initialize the map only once
    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current && mapBounds) {
            const mapInstance = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/standard',
                attributionControl: false,
            });    
            // Set the map instance to mapRef.current
            mapRef.current = mapInstance;
    
            // Use fitBounds to initialize the map with the specified bounds
            const sw = [mapBounds.getWest(), mapBounds.getSouth()];
            const ne = [mapBounds.getEast(), mapBounds.getNorth()];
            
            mapInstance.fitBounds([sw, ne], { 
                padding: 10, 
                maxZoom: 20, 
                duration: 0 // Disable animation
            });    
            // Attach custom methods to the map instance if needed
            mapRef.current.highlightMarker = (listingId) => highlightMarker(listingId);
            mapRef.current.resetMarker = (listingId) => resetMarker(listingId);
        }
    }, [mapBounds]);
    

    useEffect(() => {
        if (mapRef.current) {
            addMarkers(markers);
        }
    }, [markers,mapRef]);

    
    
    return (<div ref={mapContainerRef} className="map-container" style={{ position: 'relative' }}>
           
            {selectedListing && (
                <div ref={listingRef}
                    style={{
                        position: 'absolute',
                        top: `${listingPosition.top}%`,
                        left: `${listingPosition.left}%`,
                        backgroundColor: 'white',
                        width:'20vw',
                        padding: '0px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transform: 'translate(-50%, -100%)',  // Adjusts the box to be above the marker
                        zIndex: '10'
                    }}
                >
                    {loadingListing ? (
                        <div>Loading...</div>
                    ) : (
                        <ListingItem listing={selectedListing} onFavoriteChange={onFavoriteChange} />
                    )}
                </div>
            )}
        </div>
    )
};

export default ListingsMap;
