import React, { useState, useRef, useEffect } from 'react';

const ListingTypeFilter = ({ onListingTypeChange, appliedListingType }) => {
  const [showListingTypeFilter, setShowListingTypeFilter] = useState(false);
  const [selectedListingType, setSelectedListingType] = useState(appliedListingType || 'venta');
  const listingTypeFilterRef = useRef(null);

  // Define your listing type options here
  const options = [
    { label: 'Venta', value: 'venta' },
    { label: 'Preventa', value: 'preventa' },
  ];

  const handleClickOutside = (e) => {
    if (listingTypeFilterRef.current && !listingTypeFilterRef.current.contains(e.target)) {
      setShowListingTypeFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const applyListingTypeFilter = () => {
    // If an empty string is selected, we treat it as null (no filter)
    onListingTypeChange(selectedListingType === '' ? null : selectedListingType);
    setShowListingTypeFilter(false);
  };

  const buildButtonText = () => {
    if (appliedListingType && appliedListingType !== '') {
      const selectedOption = options.find(opt => opt.value === appliedListingType);
      return selectedOption ? selectedOption.label : 'Tipo';
    }
    return 'Tipo';
  };

  return (
    <div className="filter-button-container" ref={listingTypeFilterRef}>
      <button
        onClick={() => setShowListingTypeFilter(!showListingTypeFilter)}
        className="filter-button"
      >
        {buildButtonText()}
      </button>
      {showListingTypeFilter && (
        <div className="filter-detail">
          <div className="filter-options">
            {options.map((option) => (
              <div
                key={option.value}
                className={`filter-option-item ${selectedListingType === option.value ? 'selected' : ''}`}
                onClick={() => setSelectedListingType(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={applyListingTypeFilter}
            className="apply-filter-button"
          >
            Aplicar
          </button>
        </div>
      )}
    </div>
  );
};

export default ListingTypeFilter;
