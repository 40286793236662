import React from 'react';
// Import Mapbox GL JS and CSS
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;


const Step2ConfirmInfo = ({ geoData, listingData, onChange, nextStep, prevStep }) => {
  const handleEdit = () => {
    prevStep();
  };

  const handleCreateListing = () => {
    // Set the listing as not active
    nextStep();
  };

  // Initialize the map when the component mounts
  React.useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;

    const map = new mapboxgl.Map({
      container: 'map', // ID of the element to mount the map in
      style: 'mapbox://styles/mapbox/standard',
      center: [geoData.center[0], geoData.center[1]], // Replace with actual coordinates
      zoom: 15,
      attributionControl: false
    });

    new mapboxgl.Marker().setLngLat([geoData.center[0], geoData.center[1]]).addTo(map);

    // Clean up on unmount
    return () => map.remove();
  }, [geoData]);

  return (
    <div className="step-container">
      <div className="step-box">
      <div className="step-title">Paso 2: Confirmación</div>
      <div>
        <p><strong>Dirección:</strong> {geoData.place_name}</p>
        <p><strong>Tipo de Propiedad:</strong> {listingData.property_type}</p>
        {/* Add other fields as needed */}
        <div id="map" style={{ width: '100%', height: '400px' }}></div>
      </div>
      <div className='step-controls'>
      <div className="step-back" onClick={handleEdit}>Regresar</div>
      <button className="next-step-button" onClick={handleCreateListing}>Next</button>
      </div>
    </div>
    </div>
  );
};

export default Step2ConfirmInfo;
