import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_GEOCODING;
mapboxgl.accessToken = mapboxAccessToken;

const SearchBar = ({ onLocationSelect }) => {
    const geocoderContainerRef = useRef(null); // Reference to geocoder container div
    const geocoderRef = useRef(null); // Reference to store geocoder instance
    const onLocationSelectRef = useRef(onLocationSelect); // Store location select handler
    const geocoderResultListener = useRef(null); // Store the listener function reference

    // Update the ref if onLocationSelect changes
    useEffect(() => {
        onLocationSelectRef.current = onLocationSelect;
    }, [onLocationSelect]);

    useEffect(() => {
        const initializeGeocoder = () => {
            if (geocoderContainerRef.current) {
                // Clear any previous content inside the geocoder container
                geocoderContainerRef.current.innerHTML = '';

                // Initialize the MapboxGeocoder instance
                geocoderRef.current = new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    placeholder: 'Calle, CP, Colonia, Ciudad',
                    types: 'address, postcode, neighborhood, place',
                    countries: 'mx',
                    limit: 5,
                    language: 'es',
                    fuzzyMatch: false,
                });

                // Add the geocoder to the container
                geocoderRef.current.addTo(geocoderContainerRef.current);

                // Define the listener function and store it in the ref
                geocoderResultListener.current = (event) => {
                    const location = event.result;
                    onLocationSelectRef.current(location); // Use the current location select handler

                    // Blur the input field after a location is selected to hide the keyboard
                    const input = geocoderContainerRef.current.querySelector('input');
                    if (input) {
                        input.blur(); // This will hide the keyboard and remove focus from the input
                    }
                };

                // Attach the result event listener to handle location selection
                geocoderRef.current.on('result', geocoderResultListener.current);
            }
        };

        // Run the initialization after a short delay to ensure DOM is ready
        const timeoutId = setTimeout(initializeGeocoder, 100);

        // Clean up the geocoder when the component unmounts
        return () => {
            clearTimeout(timeoutId);
            if (geocoderRef.current) {
                if (geocoderResultListener.current) {
                    geocoderRef.current.off('result', geocoderResultListener.current); // Remove the event listener
                }
                geocoderRef.current.clear(); // Clear the geocoder instance
            }
        };
    }, []); // Empty dependency array, runs only on mount and unmount

    return <div ref={geocoderContainerRef} className="geocoder-container" />;
};

export default SearchBar;
