// src/pages/Register.js

import React, { useState } from 'react';
import { registerUser } from '../api'; // Import the API call function
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../styles/register.css'; // Add CSS for styling

const Register = () => {
    const navigate = useNavigate(); // Initialize the navigate function
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password1: '',
        password2: '',
    });

    const [error, setError] = useState(null);
    // Remove the success state since we will redirect upon success
    // const [success, setSuccess] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await registerUser(formData); // Call the API
            // Redirect to the login page upon successful registration
            navigate('/login', { state: { registered: true } });
        } catch (err) {
            setError(err);
        }
    };

    return (
        <div className="register-container">
            <div className="register-box">
                <div className='h2-register'>Registro</div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            name="username"
                            placeholder="Username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            name="password1"
                            placeholder="Password"
                            value={formData.password1}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            name="password2"
                            placeholder="Confirm Password"
                            value={formData.password2}
                            onChange={handleChange}
                            required
                            />
                    </div>
                    <button type="submit" className="register-button">Enviar</button>
                </form>
                {error && <p className="error-message">Error: {JSON.stringify(error)}</p>}
            </div>
        </div>
    );
};

export default Register;
