import React, { useState, useEffect } from 'react';
import Step1BasicInfo from './Step1BasicInfo';
import Step2ConfirmInfo from './Step2ConfirmInfo';
import Step3AdditionalDetails from './Step3AdditionalDetails';
import '../../styles/create_listing.css'
import { useNavigate } from 'react-router-dom'; // Import for navigation



const initialListingData = {
    address: {},
    description:'', 
    property_type: '', // 'house', 'apartment', 'office'
    listing_type: '', // 'rent' or 'sell'
    price: '',
    contact_phone: '',
    number_of_rooms: '',
    number_of_bathrooms: '',
    number_of_half_bathrooms: '',
    construction_area:'',
    uploaded_images: [],
    latitude:'',
    longitude:''
    // Add other optional fields as needed
  };

const CreateListingForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [listingData, setListingData] = useState(initialListingData);
  const [geoData, setGeoData] = useState();
  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Authentication state
  const navigate = useNavigate(); // For navigation

  // Check authentication status when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token && token !== 'undefined') {
      // Optionally verify if the token is valid (e.g., not expired)
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      // Redirect to login page
      navigate('/login', { state: { fromCreateListing: true } }); // Adjust the path to your login route
      console.log('not auth, redirecting to login')
    }
  }, [navigate]);

  // If authentication status hasn't been determined yet, show a loading indicator
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // If the user is not authenticated, you can redirect or show a message
  if (!isAuthenticated) {
    return <div>You must be logged in to create a listing.</div>;
  }

  const handleDataChange = (newData) => {
    setListingData((prevData) => ({ ...prevData, ...newData }));
    console.log(listingData)
  };
  const handleGetGeoData = (data) => {
    setGeoData(data);
    console.log(data)
  };

  switch (currentStep) {
    case 1:
      return (
        <Step1BasicInfo
          listingData={listingData}
          onChange={handleDataChange}
          nextStep={nextStep}
          getGeoData={handleGetGeoData}
        />
      );
    case 2:
      return (
        <Step2ConfirmInfo
          geoData={geoData}
          listingData={listingData}
          onChange={handleDataChange}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 3:
      return (
        <Step3AdditionalDetails
          listingData={listingData}
          onChange={handleDataChange}
          prevStep={prevStep}
        />
      );
    default:
      return <div>Invalid step</div>;
  }
};

export default CreateListingForm;
