// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    border-top: 1px solid #e6e6e6;
    padding: 5px;
    font-size: 0.9rem;
    color: #333;
    height: 9%;
    box-sizing: border-box;
    /* Flexbox properties for alignment */
    display: flex;
    /* Center text horizontally */
    align-items: flex-end;
    justify-content: center;
    /* Align text to the bottom */
}

.footer-content * {
    display: flex;
    justify-content: center;
}

.footer-content a {
    color: #0066cc;
    text-decoration: none;
}

.footer-content a:hover {
    text-decoration: underline;
}

.footer-content h4 {
    margin: 1px;
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,UAAU;IACV,sBAAsB;IACtB,qCAAqC;IACrC,aAAa;IACb,6BAA6B;IAC7B,qBAAqB;IACrB,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".footer-container {\n    border-top: 1px solid #e6e6e6;\n    padding: 5px;\n    font-size: 0.9rem;\n    color: #333;\n    height: 9%;\n    box-sizing: border-box;\n    /* Flexbox properties for alignment */\n    display: flex;\n    /* Center text horizontally */\n    align-items: flex-end;\n    justify-content: center;\n    /* Align text to the bottom */\n}\n\n.footer-content * {\n    display: flex;\n    justify-content: center;\n}\n\n.footer-content a {\n    color: #0066cc;\n    text-decoration: none;\n}\n\n.footer-content a:hover {\n    text-decoration: underline;\n}\n\n.footer-content h4 {\n    margin: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
