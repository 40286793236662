// src/pages/Homepage.js

import React from 'react';
import '../styles/styles.css';
import SearchBar from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import '../styles/homepage.css'
// Import images from the local repository
import mtyImage from '../images/mty.jpg';
import sanPedroImage from '../images/san_pedro.jpg';
import santaCatarinaImage from '../images/santa_catarina.jpg';
import Footer from './Footer';


const Homepage = () => {
  const navigate = useNavigate();

  // Handler for when a location is selected in the SearchBar
  const handleLocationSelect = (location) => {
    console.log(location)
    const [swLng, swLat, neLng, neLat] = location.bbox;

    // Ensure center is available and has latitude and longitude
    if (swLng && swLat && neLng && neLat) {
      // Navigate to the listings page with the selected location's coordinates
      navigate(`/listings?swLat=${swLat}&swLng=${swLng}&neLat=${neLat}&neLng=${neLng}`);
    }
  };

  // Hardcoded city data with names, centers, and image URLs
  const cities = [
    {
      name: 'Monterrey',
      url: "/listings?swLat=25.57053499939937&swLng=-100.44817046562524&neLat=25.75722957113736&neLng=-100.18602325255348&listingType=venta",
      imageUrl: mtyImage,
    },
    {
      name: 'San Pedro',
      url: "/listings?swLat=25.60013269137569&swLng=-100.43019088459293&neLat=25.71523334866312&neLng=-100.31173509781006&listingType=venta",
      imageUrl: sanPedroImage,
    },
    {
      name: 'Santa Catarina',
      url: "/listings?swLat=25.62571128314582&swLng=-100.51003145303319&neLat=25.733703792391168&neLng=-100.40016689093581&listingType=venta",
      imageUrl: santaCatarinaImage,
    },
  ];

  // Handler for when a city box is clicked
  const handleCityClick = (url) => {

    // Navigate to the listings page with the city's hardcoded coordinates
    navigate(url);
};

  return (
    <div className="homepage">
      <div className="hero">
        <div className="hero-content">
          <h1>Zittu</h1>
          <h2>Encuentra tu Sitio</h2>
          {/* Include the SearchBar with the handleLocationSelect handler */}
          <SearchBar onLocationSelect={handleLocationSelect} />
        </div>
      </div>

      {/* City Boxes Section */}
      <div className="city-boxes">
        {cities.map((city) => (
          <div
            key={city.name}
            className="city-box"
            onClick={() => handleCityClick(city.url)}
          >
            <img src={city.imageUrl} alt={city.name} className="city-image" />
            <div className="city-name">{city.name}</div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
